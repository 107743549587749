import React, { useEffect, useState } from 'react';
import MaskedInput from "react-input-mask";
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';
import { Controller } from "react-hook-form";
import api from '../../services/api';
import { updateUserInfo } from '../../services/user';
import { useHistory } from "react-router-dom";
import InlineSVG from 'svg-inline-react';
import SuccessEdit from './SuccessEdit';



const ProfileStep1 = ({userData, formObject, stepStatus, setStepFinished, checkSign, stepFinished }) => {
  const history = useHistory();

  const [forceShowForm, setForceShowForm] = useState();
  
  const { handleSubmit, errors, register, setValue, reset: resetForm, control, getValues } = formObject;

  const handleVisibleChange = ({target}) => setValue('profileInvisible', !target.checked);
  const handleInvisibleChange = ({target}) => setValue('profileVibisble', !target.checked);

  useEffect(() => {
    if(userData) {
      resetForm({
        name: userData.userName,
        whatsapp: userData.whatsapp,
        tradeDinheiro: userData.tradeDinheiro,
        tradePermuta: userData.tradePermuta,
        profileVibisble: userData.isVisible,
        profileInvisible: !userData.isVisible
      });
      setValue('whatsapp', userData.whatsapp);
    }
  }, [userData, resetForm, setValue]);
  
  const onSubmit = async(data) => {
    const {
      name,
      whatsapp,
      tradeDinheiro,
      tradePermuta,
      profileVibisble
    } = data;
    
      try {
        const result = await api.post('/user-details', {
          step: 1,
          name: name,
          whatsapp: whatsapp,
          tradeDinheiro: tradeDinheiro,
          tradePermuta: tradePermuta,
          isVisible: profileVibisble
        });
        await updateUserInfo(result.data);
        setStepFinished(true);
        setForceShowForm(false);
        history.push('/meu-perfil');
      } catch(err){
        window.alert('Erro interno do servidor: ' + err);
        setStepFinished(false);
        setForceShowForm(false);
      }
  };

  return (
      <AccordionItem className="windbox -item">
        <AccordionItemHeading className="windbox -head">
          <AccordionItemButton className="windbox -button">
            <div className="windbox -button-target">
              {stepStatus['1'] &&
                <InlineSVG src={checkSign} className="windbox -position -finished"/>
              }
              {!stepStatus['1'] &&
                <span className="windbox -position">1</span>
              }
              <h3 className="windbox -title">Minha conta</h3>
            </div>
            <span className="windbox -icon">
              <i className="icon"></i>
              <span className="sr-only">abrir</span>
            </span>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel className="windbox -panel">
          <div className="windbox -content">
            {
              <form onSubmit={ handleSubmit(onSubmit)} noValidate className={`${!stepFinished || forceShowForm ? '' : 'form -hide'}`}>
                <div className={`form -field ${ errors.name ? '-error' : ''}`}>
                  <label htmlFor="name">
                    Qual o seu nome?
                  </label>
                  <div className="form -group -gray">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      autoComplete="off"
                      placeholder="Seu nome"
                      ref={register({
                        required: true,
                        maxLength: 40,
                        minLength: 3
                      })}
                    />
                    { errors.name &&
                      <p className="form -message -error">Por favor, preencha um nome válido</p>
                    }
                  </div>
                </div>
                <div className={`form -field ${errors.whatsapp ? '-error' : ''}`}>
                  <label htmlFor="whatsapp">
                    Qual o seu telefone / whatsapp?
                  </label>
                  <div className="form -group -gray">
                    <Controller
                      as={MaskedInput}
                      control={control}
                      name="whatsapp"
                      mask="(99) 99999-9999"
                      placeholder="(DDD) 00000-0000"
                      defaultValue={userData.whatsapp}
                      rules={{
                        required: false,
                        validate: value => (
                          value.replace(/\D/g,'').length === 10 ||
                          value.replace(/\D/g,'').length === 11 ||
                          value.length < 1
                          )
                      }}
                    />
                    { errors.whatsapp &&
                      <p className="form -message -error">Por favor, preencha seu whatsapp</p>
                    }
                  </div>
                </div>
                <div className={`form -field`}>
                  <label>
                    Como você está disponível para trocar produtos e serviços?
                  </label>
                  <div className="form -group -gray -double-inline">
                    <div className="checkbox">
                      <label className="container">
                        <span className="text">Com dinheiro</span>
                        <input 
                          type="checkbox"
                          name="tradeDinheiro"
                          ref={register({
                            validate: value => value || getValues('tradePermuta')
                          })}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div className="checkbox">
                      <label className="container">
                        <span className="text">Sem dinheiro (Permuta)</span>
                        <input 
                          type="checkbox"
                          name="tradePermuta"
                          ref={register({
                            validate: value => value || getValues('tradeDinheiro')
                          })}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                    { (errors.tradePermuta || errors.tradeDinheiro) &&
                      <p className="form -message -error">Você precisa selecionar ao menos uma opção</p>
                    }
                </div>
                <div className={`form -field`}>
                  <label>
                    Manter sua conta
                  </label>
                  <div className="form -group -gray -double-inline">
                    <div className="checkbox">
                      <label className="container">
                        <span className="text">Visível para todos</span>
                        <input 
                          type="checkbox"
                          name="profileVibisble"
                          onChange={handleVisibleChange}
                          ref={register({})}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div className="checkbox">
                      <label className="container">
                        <span className="text">Invisível para todos</span>
                        <input 
                          type="checkbox"
                          name="profileInvisible"
                          onChange={handleInvisibleChange}
                          ref={register({})}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <div className="invisible-notice b-light text -tiny c-base">
                    Contas invisíveis ficam ocultas na plataforma e não recebem contatos.
                  </div>
                </div>
                <div className="form -field center mt-4">
                  <button type="submit" className="button -register -median">
                    <span className="button -text">
                      Salvar e continuar
                    </span>
                  </button>
                </div>
              </form>
            }
            {stepFinished && !forceShowForm &&
              <SuccessEdit setForceShow={setForceShowForm} />
            }
          </div>
        </AccordionItemPanel>
      </AccordionItem>
  );
}

export default ProfileStep1;