import React from 'react';




const IncompleteProfile = ({ isOwn, userId, registrationStatus}) => {


  return (

    <div className="incomplete-profile -wrap-incomplete center">

      <h3 className="incomplete-profile -title">
        <span>Preencha todo seu perfil</span>
      </h3>

      <div className="incomplete-profile -incomplete-group">
        <span>Para que as pessoas consigam visualizar o seu perfil no ContaComigo, você precisa preencher todos os campos de cadastro abaixo, tá? Não se esqueça disso. ;)</span>
        {false &&<strong className="-steps-finished">Etapas concluídas: {registrationStatus?.ok || 0}/{registrationStatus?.total || 4}</strong>}

      </div>

    </div>

  );
}

export default IncompleteProfile;