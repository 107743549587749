import React from "react";
import { scroller } from 'react-scroll';

const ButtonScroll = (props) => {

  /**
   * Mecanismo hover dos botões
   */
  const handleEnterButton = (e) => {

    document.querySelectorAll('.button.-circle').forEach( (circle) => {
      let relX = e.pageX - e.target.offsetParent.offsetLeft;
      let relY = e.pageY - e.target.offsetParent.offsetTop;

      circle.style.left = relX+'px';
      circle.style.top = relY+'px';
      circle.classList.remove('-desplode');
      circle.classList.add('-explode');
    });
  }

  const handleLeaveButton = (e) => {

    document.querySelectorAll('.button.-circle').forEach( (circle) => {
      let relX = e.pageX - e.target.offsetParent.offsetLeft;
      let relY = e.pageY - e.target.offsetParent.offsetTop;

      circle.style.left = relX+'px';
      circle.style.top = relY+'px';
      circle.classList.remove('-explode');
      circle.classList.add('-desplode');
    });
  }

  const handleScrollTo = (e) => {
    e.preventDefault();

    scroller.scrollTo('facaparte', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: 0
    });
  }

  return (
    <>

    <div className={`button -wrap mt-3 mt-lg-4 ${props.class}`}>

      <span className="button -circle"></span>
      <a 
        href={props.href || "/"}
        className={`button -link -larger ${props.bgColor || 'b-1'} ${props.textColor || ''}`} 
        onClick={handleScrollTo}
        onMouseEnter={handleEnterButton} 
        onMouseLeave={handleLeaveButton} 
        title={props.title}>
        <span className="button -text">
          {props.title}
        </span>
      </a>

    </div>

    </>
  );
};

export default ButtonScroll;
