import React, { useEffect, useState } from 'react';
import Menu from '../../components/Menu';
import Share from "../../components/Share";
import SearchBox from '../../components/SearchBox';
import api from '../../services/api';
import { useHistory } from "react-router-dom";

/*
const profile = [
  {
    id: '1',
    url: 'temp-persona-13.jpg',
    name: 'Priscila Senegale Coutto',
    title: [
      {
        id: '11',
        name: 'Redatora',
      },
    ],
    option: 'favor',
    abstract: 'Sou redatora freelancer há quatro anos e tenho experiência com produção de conteúdo para web e SEO. Sou redatora em período integral e tenho disponibilidade para demandas urgentes e/ou recorrentes.',
    href: 'perfil',
  },
  {
    id: '2',
    url: 'temp-persona-1.jpg',
    name: 'José Beto',
    title: [
      {
        id: '21',
        name: 'pintor',
      },
      {
        id: '22',
        name: 'decorador de paredes',
      },
      {
        id: '23',
        name: 'pintor de móveis',
      },
    ],
    option: 'payment',
    abstract: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    href: 'perfil',
  },
  {
    id: '3',
    url: 'temp-persona-2.jpg',
    name: 'Melissa de Souza',
    title: [
      {
        id: '31',
        name: 'Apicultora',
      },
      {
        id: '31',
        name: 'Engenheira agrônoma',
      },
    ],
    option: 'payment',
    abstract: 'Sou presidente da Associação de Apicultores, hoje estamos com 22 associados ativos e tenho muito interesse em falar sobre apicultura, sobre abelhas, pois elas estão a milhares de anos a nossa frente, a sua inteligência é superior a nossa, então é com prazer que somos apicultores.',
    href: 'perfil',
  },
  {
    id: '4',
    url: 'temp-persona-3.jpg',
    name: 'Venâncio Soares Prestes',
    title: [
      {
        id: '41',
        name: 'Motoboy',
      },
    ],
    option: 'payment',
    abstract: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    href: 'perfil',
  },
  {
    id: '5',
    url: 'temp-persona-4.jpg',
    name: 'Sirlenny Melina',
    title: [
      {
        id: '51',
        name: 'Doceira',
      },
      {
        id: '52',
        name: 'Confeiteira',
      },
      {
        id: '53',
        name: 'Pâtisserie',
      },
    ],
    option: 'favor',
    abstract: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    href: 'perfil',
  },
  {
    id: '6',
    url: 'temp-persona-5.jpg',
    name: 'Merciano Oliveira',
    title: [
      {
        id: '61',
        name: 'Acompanhante de idosos',
      },
    ],
    option: 'favor',
    abstract: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    href: 'perfil',
  },
  {
    id: '7',
    url: 'temp-persona-6.jpg',
    name: 'Vera Lúcia',
    title: [
      {
        id: '71',
        name: 'Caixa de supermercado',
      },
    ],
    option: 'payment',
    abstract: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    href: 'perfil',
  },
  {
    id: '8',
    url: 'temp-persona-7.jpg',
    name: 'Murilo Pereira',
    title: [
      {
        id: '81',
        name: 'Aposentado',
      },
      {
        id: '82',
        name: 'Motorista de caminhão',
      },
    ],
    option: 'favor',
    abstract: 'Eu ofereço serviço de carreto. Faço entrega com um caminhão baú de médio porte.',
    href: 'perfil',
  },
  {
    id: '9',
    url: 'temp-persona-8.jpg',
    name: 'Liliam Ana Silva',
    title: [
      {
        id: '91',
        name: 'Gestora de investimentos',
      },
    ],
    option: 'favor',
    abstract: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    href: 'perfil',
  },
  {
    id: '10',
    url: 'temp-persona-9.jpg',
    name: 'Benedito',
    title: [
      {
        id: '101',
        name: 'Lanterneiro',
      },
      {
        id: '102',
        name: 'Folheiro',
      },
    ],
    option: 'payment',
    abstract: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    href: 'perfil',
  },
  {
    id: '11',
    url: 'temp-persona-10.jpg',
    name: 'Verônica Volpato',
    title: [
      {
        id: '111',
        name: 'Ginecologista',
      },
      {
        id: '112',
        name: 'Médica clínica geral',
      },
    ],
    option: 'payment',
    abstract: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    href: 'perfil',
  },
  {
    id: '12',
    url: 'temp-persona-11.jpg',
    name: 'Titina',
    title: [
      {
        id: '121',
        name: 'Paisagista',
      },
    ],
    option: 'favor',
    abstract: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    href: 'perfil',
  },
  {
    id: '13',
    url: 'temp-persona-12.jpg',
    name: 'Roberto Salles',
    title: [
      {
        id: '131',
        name: 'Mecânico',
      },
      {
        id: '132',
        name: 'Funileiro',
      },
      {
        id: '133',
        name: 'Chapeiro',
      },
      {
        id: '134',
        name: 'Aposentado',
      },
    ],
    option: 'payment',
    abstract: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    href: 'perfil',
  },
  {
    id: '14',
    url: 'temp-persona-13.jpg',
    name: 'Priscila Senegale Coutto',
    title: [
      {
        id: '11',
        name: 'Redatora',
      },
    ],
    option: 'favor',
    abstract: 'Sou redatora freelancer há quatro anos e tenho experiência com produção de conteúdo para web e SEO. Sou redatora em período integral e tenho disponibilidade para demandas urgentes e/ou recorrentes.',
    href: 'perfil',
  },
  {
    id: '15',
    url: 'temp-persona-1.jpg',
    name: 'José Beto',
    title: [
      {
        id: '21',
        name: 'pintor',
      },
      {
        id: '22',
        name: 'decorador de paredes',
      },
      {
        id: '23',
        name: 'pintor de móveis',
      },
    ],
    option: 'payment',
    abstract: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    href: 'perfil',
  },
  {
    id: '16',
    url: 'temp-persona-2.jpg',
    name: 'Melissa de Souza',
    title: [
      {
        id: '31',
        name: 'Apicultora',
      },
      {
        id: '31',
        name: 'Engenheira agrônoma',
      },
    ],
    option: 'payment',
    abstract: 'Sou presidente da Associação de Apicultores, hoje estamos com 22 associados ativos e tenho muito interesse em falar sobre apicultura, sobre abelhas, pois elas estão a milhares de anos a nossa frente, a sua inteligência é superior a nossa, então é com prazer que somos apicultores.',
    href: 'perfil',
  },
  {
    id: '17',
    url: 'temp-persona-3.jpg',
    name: 'Venâncio Soares Prestes',
    title: [
      {
        id: '41',
        name: 'Motoboy',
      },
    ],
    option: 'payment',
    abstract: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    href: 'perfil',
  },
  {
    id: '18',
    url: 'temp-persona-4.jpg',
    name: 'Sirlenny Melina',
    title: [
      {
        id: '51',
        name: 'Doceira',
      },
      {
        id: '52',
        name: 'Confeiteira',
      },
      {
        id: '53',
        name: 'Pâtisserie',
      },
    ],
    option: 'favor',
    abstract: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    href: 'perfil',
  },
];
*/

const ProdutosServicos = () => {
  const history = useHistory();

  const [ profileList, setProfileList ] = useState();


  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const userResponse = await api.get(`/users/search`);
        setProfileList(userResponse.data);
      } catch(err){
        history.push('/404');
      }
    }
    if(!profileList){
      fetchProfile();
    }
  }, [ profileList, history ]);


  return (
    <>
      <Menu theme="tertiary" login={''} />
      <section className="page -container">
        <SearchBox />
      </section>
      <Share />
    </>
  );
};

export default ProdutosServicos;