import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../../animations/loading.json';

const Loading = (props) => {

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <section id="loadingSpinner" className="loading -off">
      <div>
        <Lottie options={defaultOptions}
                height={300}
                width={300}
                isStopped={false}
                isPaused={false}/>
      </div>
    </section>
  );
};

export default Loading;
