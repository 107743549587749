import React from 'react';
import { Link } from 'react-router-dom';

const slugify = text =>
text
  .toString()
  .normalize('NFD')
  .replace(/[\u0300-\u036f]/g, '')
  .toLowerCase()
  .trim()
  .replace(/\s+/g, '-')
  .replace(/[^\w-]+/g, '')
  .replace(/--+/g, '-');

const ListItem = ({ profile }) => {

  const userProfilePath = `usuario/perfil/${profile.userId}/${slugify(profile.userName)}`;

  const userShortName = profile?.userName.length > 33 ? `${profile.userName.slice(0, 30)}...` : profile.userName;

  return (
    <div className="explore -list-item">
      <div className="explore -profile">
        <picture className="explore -image">
          <Link to={userProfilePath} className="explore -image-link">
            {profile.userProfilePicture &&
              <div className="image-container" style={{backgroundImage: `url('${process.env.REACT_APP_PUBLIC_STORAGE_BASE_URL + profile.userProfilePicture}')`}} />
            }
            {!profile.userProfilePicture &&
              <div className="image-container" style={{backgroundImage: `url('${require('../../../assets/images/profilePlaceholder.svg')}')`}} />
            }
            {!!profile.tradePermuta &&
              <span className={`explore -flag favor`}>
                Permuta
              </span>
            }
            {!!(!profile.tradePermuta && profile.tradeDinheiro) &&
              <span className={`explore -flag payment`}>
                Dinheiro
              </span>
            }
          </Link>
        </picture>
        <div className="explore -info">
          {profile.userName &&
            <h4 className="explore -name">
              <Link to={userProfilePath}>
                <span>{userShortName}</span>
              </Link>
            </h4>
          }
          {profile.profissaoId &&
            <div className="explore -details">
                <h3 className="explore -title" key={`${profile.userName}-${profile.profissaoId}`}>
                  <span>{profile.profissaoName}</span>
                </h3>
            </div>
          }
          {profile.userAboutSelf &&
            <div className="explore -abstract">
              <p>
                {profile.userAboutSelf}
              </p>
            </div>
          }
          <Link to={userProfilePath} className="explore -link">
            <span>...&nbsp;<b>saber mais</b></span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ListItem;