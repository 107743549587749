import React from 'react';

const Aside = (props) => {

  return (

    <div className="aside -wrap">

      <img src={require('../../assets/images/temp/' + props.cover)} alt={props.title} />

      <div className="aside -info">

        <h4 className="aside -name">
          <span>{props.name}</span>
        </h4>

        <h3 className="aside -title">
          <span>{props.title}</span>
        </h3>

      </div>

    </div>
  );
}

export default Aside;