import React, { useEffect, useState } from 'react';
import Filter from '../Listing/Filter';
import ListItem from '../Listing/ListItem';
import api from '../../services/api';
import { useHistory } from "react-router-dom";



const SearchBox = () => {
  const history = useHistory();

  const [ profileList, setProfileList ] = useState();
  const [ searchValue, setSearchValue ] = useState();
  const [ lastSearchValue, setLastSearchValue ] = useState();


  useEffect(() => {
    const fetchProfile = async () => {
      const searchSpinner = document.querySelector('div#searchBoxLoader');
      
      if(profileList) {
        searchSpinner.classList.remove('-off');
      }
      try {
        const userResponse = await api.get(
          `/users/search${searchValue ? '?search='+searchValue : ''}`, {
            noSpinner: true
          }
          );
        setProfileList(userResponse.data);
      } catch(err){
        history.push('/404');
      }
      finally{
        searchSpinner.classList.add('-off');
      }
    }
    if(!profileList || 
      (((searchValue || '').length > 2 || (searchValue || '').length === 0) && 
      (lastSearchValue !== searchValue))) 
      {
        fetchProfile();
        setLastSearchValue(searchValue);
    }
  }, [ profileList, history, searchValue, lastSearchValue ]);


  return (
    <>
      <Filter searchValue={searchValue} setSearchValue={setSearchValue} />
      <section className="explore -page">
        <div className="container">
          <h2 className="text -median -semi">Mais recentes</h2>
          <div className="explore -listing">
            { profileList && profileList.length > 0 && profileList.map( (data,index) => {
              return (
                <ListItem profile={data} key={`userSearchList-${index}`} />
              );
            })}
            { profileList && profileList.length === 0 &&
              <span className="explore -noresults">Ops! Ainda não temos resultados para essa busca.</span>
            }
            { !profileList &&
              <span className="explore -noresults">Carregando informações...</span>
            }
          </div>
        </div>
      </section>
    </>
  );
};

export default SearchBox;