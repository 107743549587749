import React, { useState, useEffect } from "react";
import { Accordion } from 'react-accessible-accordion';
import { getUserObject } from '../../../services/auth'
import { useForm } from 'react-hook-form';

import api from '../../../services/api';

import ProfileStep1 from '../../../components/EditPerfil/ProfileStep1';
import ProfileStep2 from '../../../components/EditPerfil/ProfileStep2';
import ProfileStep3 from '../../../components/EditPerfil/ProfileStep3';
import ProfileStep4 from '../../../components/EditPerfil/ProfileStep4';

const checkSign = `<svg xmlns="http://www.w3.org/2000/svg" width="15.12" height="12" viewBox="0 0 15.12 12">
<g id="Grupo_70" data-name="Grupo 70" transform="translate(-1125.586 -237.343)">
  <rect id="Rectangle" width="7.92" height="3.394" rx="1" transform="translate(1127.986 241.343) rotate(45)" fill="#fff"/>
  <rect id="Rectangle-2" data-name="Rectangle" width="13.576" height="3.394" rx="1" transform="translate(1140.705 239.743) rotate(135)" fill="#fff"/>
</g>
</svg>
`;

const Formulario = ({ stepStatus, setStepStatus, setRegistrationStatus }) => {

  // =============Fetching data
  const [userData, setUserData] = useState();
  const [areaList, setAreaList] = useState();
  const [profissaoList, setProfissaoList] = useState();
  const [dataRequestFinished, setDataRequestFinished] = useState(false);

  const getData = async () => {
    if (!areaList && !profissaoList && !dataRequestFinished && !userData) {

      const fecthedData = await getUserObject();
      setUserData(fecthedData);

      const thisList = await api.get('/user-details/selects');

      let thisAreaList = thisList.data.areaAtuacao;
      let thisProfissaoList = thisList.data.profissao;

      const profissaoToRemove = thisProfissaoList.findIndex(elmt => elmt.value === fecthedData.profissao.id);

      if(fecthedData.areaAtuacao.id) {
        await thisAreaList.unshift({value: fecthedData.areaAtuacao.id, label: fecthedData.areaAtuacao.name});
      }
      if(profissaoToRemove !== -1) {
        await thisProfissaoList.unshift({value: fecthedData.profissao.id, label: fecthedData.profissao.name});
      }

      validateInitialData(fecthedData);

      setAreaList(thisAreaList);
      setProfissaoList(thisProfissaoList);
      setDataRequestFinished(true);
    }
  };
  getData();
  //====================

  // =====Creating states for step completions
  const [step1Finished, setStep1Finished] = useState(false);
  const [step2Finished, setStep2Finished] = useState(false);
  const [step3Finished, setStep3Finished] = useState(false);
  const [step4Finished, setStep4Finished] = useState(false);


  // =====Listening to changes on step status
  useEffect(() => {
    let thisStepStatus = stepStatus; 

    let initialCount = Object.values(stepStatus).reduce((a, b) => a + b, 0);

    thisStepStatus['1'] = step1Finished !== stepStatus['1'] ? step1Finished : thisStepStatus['1'];
    thisStepStatus['2'] = step2Finished !== stepStatus['2'] ? step2Finished : thisStepStatus['2'];
    thisStepStatus['3'] = step3Finished !== stepStatus['3'] ? step3Finished : thisStepStatus['3'];
    thisStepStatus['4'] = step4Finished !== stepStatus['4'] ? step4Finished : thisStepStatus['4'];

    setStepStatus(thisStepStatus);

    let finalCount = Object.values(thisStepStatus).reduce((a, b) => a + b, 0);

    if(finalCount === 4 && initialCount === 3 && !userData.isProfileFinished) window.location.reload();

    setRegistrationStatus({total: 4, ok: finalCount});

  }, [step1Finished, step2Finished, step3Finished, step4Finished, stepStatus, setStepStatus, userData, setRegistrationStatus]);


  // =====Validating current user data - checking if it's complete
  const validateInitialData = (data) => {
    if(data.isProfileFinished) {
      setStep1Finished(true);
      setStep2Finished(true);
      setStep3Finished(true);
      setStep4Finished(true);
    }
    else{
      if(data.userName && (data.tradePermuta || data.tradeDinheiro )) {setStep1Finished(true)}
      if(data.birthDate &&
        data.addressCep &&
        data.addressMain &&
        data.addressCity &&
        data.areaAtuacao &&
        data.profissao &&
        data.addressState &&
        data.aboutSelf) {setStep2Finished(true)}
      if(data.offer.products.productOne &&
        data.offer.services.serviceOne &&
        data.availableToGivenWhen &&
        data.availableToGivenTime) {setStep3Finished(true)}
      if(data.receive.products.productOne &&
        data.receive.services.serviceOne &&
        data.availableToReceiveWhen &&
        data.availableToReceiveTime) {setStep4Finished(true)}
    }
  };


  // =====Building forms
  const formStep1 = useForm();
  const formStep2 = useForm();
  const formStep3 = useForm();
  const formStep4 = useForm();


  /*
  const optionsArea = [
    "Ensino e Pesquisa",
    "Habitação",
    "Movimentos Sociais",
    "Políticas Públicas",
    "Previdência Social",
    "Saúde",
    "Sócio-jurídica",
    "Terceiro Setor"
  ];
  const optionsProfissao = [
    "Ajustador Mecânico",
    "Atendente de lojas",
    "Auxiliar de cozinha",
    "Biomédico",
    "Camareira de hotel",
    "Confeiteiro",
    "Costureiro de máquina reta",
    "Cozinheiro de bar",
    "Empregado doméstico",
    "Enfermeiro",
    "Desenvolvedor de sites",
    "Fresador",
    "Gerente de restaurante",
    "Instalador de eletroeletrônicos",
    "Lavador de veículos",
    "Mecânico de manutenção",
    "Pintor de obras",
    "Retificador",
    "Serralheiro",
    "Técnico de planejamento",
    "Técnico eletrônico",
    "Técnico mecânico",
    "Vendedor pracista"
  ];
  */


  return (
    <section>
      <Accordion className="windbox -wrap" allowZeroExpanded={true}>
        {userData && areaList && profissaoList && dataRequestFinished &&
          <>
            <ProfileStep1
              stepStatus={stepStatus}
              userData={userData}
              formObject={formStep1}
              stepFinished={step1Finished}
              setStepFinished={setStep1Finished}
              checkSign={checkSign}
            />
            <ProfileStep2 
              stepStatus={stepStatus}
              userData={userData}
              formObject={formStep2}
              areaList={areaList} profissaoList={profissaoList}
              stepFinished={step2Finished}
              setStepFinished={setStep2Finished}
              checkSign={checkSign}
            />
            <ProfileStep3
              stepStatus={stepStatus}
              userData={userData}
              formObject={formStep3}
              stepFinished={step3Finished}
              setStepFinished={setStep3Finished}
              checkSign={checkSign}
            />
            <ProfileStep4
              stepStatus={stepStatus}
              userData={userData}
              formObject={formStep4}
              stepFinished={step4Finished}
              setStepFinished={setStep4Finished}
              checkSign={checkSign}
            />
          </>
        }
      </Accordion>
    </section>
  );
};

export default Formulario;