import React from 'react';
import ProfileShare from '../Share';
import IncompleteProfile from '../Incomplete';
import InlineSVG from 'svg-inline-react';

const checkSign = `<svg xmlns="http://www.w3.org/2000/svg" width="15.12" height="12" viewBox="0 0 15.12 12">
<g id="Grupo_70" data-name="Grupo 70" transform="translate(-1125.586 -237.343)">
  <rect id="Rectangle" width="7.92" height="3.394" rx="1" transform="translate(1127.986 241.343) rotate(45)" fill="#fff"/>
  <rect id="Rectangle-2" data-name="Rectangle" width="13.576" height="3.394" rx="1" transform="translate(1140.705 239.743) rotate(135)" fill="#fff"/>
</g>
</svg>
`;

const ProfileDetail = ({ userData, stepStatus, setStepStatus }) => {
  return (
    <>
      {userData &&
        <section className="register -header">
          <div className="container">
            <div className="register -header-cnt" key="cabecalho">
              <div className="register -profile" key={userData.id}>
                <picture className="register -image">
                  {userData.profilePicture &&
                    <div className="image-container" style={{backgroundImage: `url('${process.env.REACT_APP_PUBLIC_STORAGE_BASE_URL + userData.profilePicture}')`}} />
                  }
                  {!userData.profilePicture &&
                    <div className="image-container" style={{backgroundImage: `url('${require('../../../assets/images/profilePlaceholder.svg')}')`}} />
                  }
                  {userData.option &&
                    <span className={`register -flag ${userData.option}`}>
                      {userData.option}
                    </span>
                  }
                </picture>
                <div className="register -info">
                  {userData.userName &&
                    <h4 className="register -name">
                      <span>{userData.userName}</span>
                    </h4>
                  }
                  <div className="register -details">
                    {userData.profissao &&
                      <h3 className="register -title">
                        <span>{userData.profissao.name}</span>
                      </h3>
                    }
                    {userData.isProfileFinished &&
                    <h3 className="register -title -profile-complete">
                      <InlineSVG src={checkSign} />
                      <span>Perfil completo</span>
                    </h3>
                    }
                    <span className="c-inverse trade-divider">
                      |
                    </span>
                    {userData.tradeDinheiro &&
                      <h3 className="register -title">
                        <span className={`vnetwork -flag payment-white`}>
                          Dinheiro
                        </span>
                        <span>Dinheiro</span>
                      </h3>
                    }
                    {userData.tradePermuta &&
                      <h3 className="register -title">
                        <span className={`vnetwork -flag favor-white`}>
                          Permuta
                        </span>
                        <span>Permuta</span>
                      </h3>
                    }
                    {userData.likes &&
                      <div className="register -fans -wrap">
                        {userData.fans && userData.fans.map( (fans,index) => {
                          return (
                            <div className="register -fans -item" key={index}>
                              <img src={require('../../../assets/images/temp/' + fans.url)} alt="curtida" />
                            </div>
                          );
                        })}
                        <span className="register -fans -likes">
                          {userData.likes} curtidas
                        </span>
                      </div>
                    }
                  </div>
                </div>
              </div>
              <div className="register -abstract">
                {userData.aboutSelf &&
                  <p>
                    {userData.aboutSelf}
                  </p>
                }
              </div>
            </div>
            {userData.isProfileFinished &&
              <ProfileShare isOwn={true} userData={userData} />
            }
            {!userData.isProfileFinished &&
              <IncompleteProfile isOwn={true} userId={userData.id} stepStatus={stepStatus} setStepStatus={setStepStatus} />
            }
          </div>
        </section>
      }
    </>
  );
};

export default ProfileDetail;