import React, { useState, useEffect } from 'react';
import Nav from '../../components/Navigation';
import Aside from '../../components/Aside';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import api from '../../services/api';
import { login } from '../../services/auth';
import { useHistory } from "react-router-dom";

const profile = [
  {
    id: '1',
    url: 'temp-perfil-1.jpg',
    name: 'Dona Elzira Gonçalves',
    title: 'Costureira',
  },
];


const Login = () => {

  const width = window.screen.availWidth;
  const history = useHistory();

  /**
   * Tratamento envio dados formulário
   */
  const { handleSubmit, register, errors, watch } = useForm();

  const [invalidLogin, setInvalidLogin] = useState();
  const [brokenEmail, setBrokenEmail] = useState();
  const [brokenPw, setBrokenPw] = useState();
  
  const currentPw = watch('senha');
  const currentEmail = watch('email');

  useEffect(() => {
    if(invalidLogin && (brokenEmail !== currentEmail || brokenPw !== currentPw)) {
      setInvalidLogin(false);
    }
  }, [invalidLogin, currentEmail, currentPw, brokenPw, brokenEmail]);


  const onSubmit = async(data) => {
    const { email, senha } = data;
      try {
        const result = await api.post('/auth/authenticate', { email: email, password: senha });
        login(result.data);
        setInvalidLogin(false);
        history.push('/meu-perfil');
      } catch(err){
        setBrokenEmail(email);
        setBrokenPw(senha);
        setInvalidLogin(true);
      }
  };

  return (
  
    <section className="section -entire flex -align-center b-light">

      <Nav theme="light-sm" option="back" />

      { profile && (width >= 992) && profile.map( (persona,index) => {

        return (

          <Aside cover={persona.url} name={persona.name} title={persona.title} key={index} />

        );
      })}

      <div className="container flex">

        <div className="row">

          <div className="col -c12 -c4-lg -offset6-lg mt-9 mt-md-4 mb-2">

            <h1 className="title -median">
              Faça seu login para acessar seu perfil.
            </h1>

            <div className="form mt-4">

              <form onSubmit={ handleSubmit(onSubmit)} noValidate>

                <div className={`form -field ${ (errors.email || invalidLogin) ? '-error' : ''}`}>

                  <label htmlFor="email">
                    Qual o seu e-mail?
                  </label>

                  <div className="form -group">

                    <input
                      type="email"
                      id="email"
                      name="email"
                      autoComplete="false"
                      placeholder="Seu e-mail"
                      ref={register({
                        required: 'Por favor preencha o e-mail.',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "* E-mail inválido. Por favor preencha novamente."
                        }
                      })}
                    />

                    { errors.email &&
                      <p className="form -message -error">{errors.email.message}</p>
                    }

                  </div>

                </div>

                <div className={`form -field ${(errors.senha || invalidLogin) ? '-error' : ''}`}>

                  <label htmlFor="senha">
                    Senha
                  </label>

                  <div className="form -group">

                    <input
                      type="password"
                      id="senha"
                      name="senha"
                      autoComplete="false"
                      placeholder="Escreva aqui"
                      ref={register({
                        required: 'Por favor preencha a sua senha.',
                        maxLength: 80
                      })}
                    />

                    { errors.senha &&
                      <p className="form -message -error">{errors.senha.message}</p>
                    }

                  </div>

                </div>

                <div className="form -field">

                  <div className="form -group -center-col">

                    {invalidLogin &&
                      <p className="c-2 text -tiny">Usuário e/ou senha inválidos</p>
                    }
                    <Link to="/redefinir-senha/solicitar" className="form -link">Esqueceu a senha?</Link>
                    <Link to="/cadastro" className="form -link">Ainda não tem login?</Link>

                  </div>

                </div>

                <div className="form -field center mt-2">

                  <button type="submit" className="button -link -median b-1">
                    <span className="button -text">
                      Acessar
                    </span>
                  </button>

                </div>

              </form>

            </div>
          
          </div>

        </div>

      </div>

    </section>

  );
};

export default Login;