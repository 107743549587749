import api from './api'

// Regex to verify if it's and absolute URL
export const getUserInfo = (userData) => {
  const dataObject = {
    id: userData.user.id || '',
    userName: userData.user.name || '',
    profilePicture : userData.details.profile_picture || '',

    isVisible: userData.details.is_visible || false,
    tradePermuta: userData.details.trade_permuta || false,
    tradeDinheiro: userData.details.trade_dinheiro || false,

    whatsapp: userData.details.whatsapp || '',
    birthDate: userData.details.birthDate || '',
    addressCep: userData.details.address_cep || '',
    addressMain: userData.details.address_main || '',
    addressCity: userData.details.address_city || '',
    addressState: userData.details.address_state || '',
    youtubeVideo: userData.details.youtube_video || '',
    aboutSelf: userData.details.about_self || '',
    education: userData.details.education || '',
    isProfileFinished: !!(userData.details.is_finished),

    profissao: userData.profissao ? {
      id: userData.profissao.id,
      name: userData.profissao.name
    } : {
      id: '',
      name: ''
    },

    areaAtuacao: userData.areaAtuacao ? {
      id: userData.areaAtuacao.id,
      name: userData.areaAtuacao.name
    } : {
      id: '',
      name: ''
    },

    offer: {
      products: {
        productOne: userData.products.offer[0].name || '',
        productTwo: userData.products.offer[1].name || '',
        productThree: userData.products.offer[2].name || '',
        productFour: userData.products.offer[3].name || '',
        productFive: userData.products.offer[4].name || '',
      },
      services: {
        serviceOne: userData.services.offer[0].name || '',
        serviceTwo: userData.services.offer[1].name || '',
        serviceThree: userData.services.offer[2].name || '',
        serviceFour: userData.services.offer[3].name || '',
        serviceFive: userData.services.offer[4].name || '',
      }
    },

    receive: {
      products: {
        productOne: userData.products.receive[0].name || '',
        productTwo: userData.products.receive[1].name || '',
        productThree: userData.products.receive[2].name || '',
        productFour: userData.products.receive[3].name || '',
        productFive: userData.products.receive[4].name || '',
      },
      services: {
        serviceOne: userData.services.receive[0].name || '',
        serviceTwo: userData.services.receive[1].name || '',
        serviceThree: userData.services.receive[2].name || '',
        serviceFour: userData.services.receive[3].name || '',
        serviceFive: userData.services.receive[4].name || '',
      }
    },

    availableToGivenWhen: userData.details.available_to_give_when || '',
    availableToGivenTime: userData.details.available_to_give_time || '',
    availableToReceiveWhen: userData.details.available_to_receive_when || '',
    availableToReceiveTime: userData.details.available_to_receive_time || ''
  };
  return JSON.stringify(dataObject);
}

export const getUser = () => {
  const userObject = JSON.parse(localStorage.getItem('user'));

  if(!userObject){
    return null;
  }

  userObject.parsedBirthDate = () => {
      if(userObject.birthDate.length > 5){
        const splitBirthDate = userObject.birthDate.split('-');
        userObject.birthDate = `${splitBirthDate[2]}/${splitBirthDate[1]}/${splitBirthDate[0]}`;
      }
  }

  return userObject;
}


export const updateUserInfo = async (userInfo) => {
  if(!userInfo){
    const result = await api.get('/user-details');
    userInfo = result.data;
  }
  localStorage.setItem('user', getUserInfo(userInfo));
  const parsedObject = await getUser();
  return parsedObject
}


