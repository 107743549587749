import { getUserInfo, getUser } from './user';
import api from './api';

export const TOKEN_KEY = "@contacomgio-tk";

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getUserObject = () => getUser();

export const login = async (data) => {
  localStorage.setItem(TOKEN_KEY, data.access_token);
  localStorage.setItem('user', getUserInfo(data.userData));
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem('user');
};

export const refreshToken = async () => {
  let newToken;
  try {
    const result = await api.get('/auth/refresh');
    localStorage.setItem(TOKEN_KEY, result.data.access_token);
    localStorage.setItem('user', getUserInfo(result.data.userData));
    newToken = result.data.access_token;
  } catch(err){
    console.warn('Unauthorized user');
  }
  return newToken;
};