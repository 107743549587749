import React from "react";

const VisualNetworkDetail = (props) => {

  const classMain = props.visual ? ('vnetwork -item ' + String(props.visual)) : 'vnetwork -item';

  return (
    <>

    <div className={classMain} key={props.index}>

      <picture className="vnetwork -image">
        <img src={require('../../../assets/images/temp/' + props.url)} alt={props.title} />

        {props.option &&
          <span className={`vnetwork -flag ${props.option}`}>
            {props.option}
          </span>
        }
      </picture>

      {props.name &&
        <h4 className="vnetwork -name">
          <span>{props.name}</span>
        </h4>
      }

      {props.title &&
        <h3 className="vnetwork -title">
          <span>{props.title}</span>
        </h3>
      }

      {props.abstract &&
        <p className="vnetwork -abstract">
          {props.abstract}
        </p>
      }
      
    </div>

    </>
  );
}

export default VisualNetworkDetail;