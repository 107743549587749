import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../../animations/success.json';

const lottieData = {
  loop: false,
  speed: 0.00001,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const SuccessEdit = ({ setForceShow }) => {

  

  return (
      <>
        <Lottie 
          options={lottieData}
          height={150}
          width={150}
          isStopped={false}
          isPaused={false}
        />
        <span>Informações salvas! você pode seguir para a próxima etapa ou editar estes dados novamente, <a href="#" onClick={() => {setForceShow(true)}}>clicando aqui</a></span>
      </>
  );
}

export default SuccessEdit;