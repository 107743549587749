import React, { useEffect } from "react";
import useIntersectObserver from '../../constants/useIntersectObserver';

const About = () => {

  /**
   * Animações dos elementos
   */
  const [observer, setElements, entries] = useIntersectObserver({
    threshold: 0.1,
    root: null
  });

  useEffect(() => {

    let elements = Array.from(document.getElementsByClassName('js-anime'));

    setElements(elements);
    
  }, [setElements]);

  useEffect(() => {

    entries.forEach(entry => {

      if (entry.isIntersecting) {

        setTimeout(function() {
          entry.target.style.opacity = '1';
          entry.target.classList.remove('-motion');

          observer.unobserve(entry.target);
        }, 200);
      }
    });
  }, [entries, observer]);

  return (
    <>

    <section className="section -cover b-light" name="quem-somos">

      <div className="container section -holder">

        <div className="grid -wrap -row-1-10-1">

          <article className="grid -col-a center">

            <h2 className="title -chapter upper c-4 js-anime -anime -anime-1 -motion" style={{ opacity:'0'}}>
              Quem Somos
            </h2>

            <h3 className="title -median c-4 mt-3 js-anime -anime -anime-2 -motion" style={{ opacity:'0'}}>
              Milhares estão ficando desempregados.<br />
              <span className="title -smaller-sm">
                Independentemente da idade e área de atuação. <br />
                Então, o que vamos fazer com isso?
              </span>
            </h3>

          </article>

        </div>

        <div className="grid -wrap -row-3-6-3 mt-4 js-anime -anime -anime-3 -motion" style={{ opacity:'0'}}>

          <article className="grid -col-a center">

            <p className="text -smaller left-sm">
              Somos um grupo de voluntários mineiros - formado por publicitários, designers e desenvolvedores - que acredita no poder da colaboração, da inovação, da tecnologia e, sobretudo, no poder do bem. Nesse momento em que a distância salva, a tecnologia aproxima. 
            </p>

          </article>

        </div>

      </div>

    </section>

    </>
  );
}

export default About;
