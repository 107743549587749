import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { useParams } from "react-router";

import { isAuthenticated } from "../services/auth";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import Home from '../pages/Home';
import Login from '../pages/Login';
import Senha from '../pages/Senha';
import SenhaToken from '../pages/Senha/Token';
import Questionario from '../pages/Questionario';
import Cadastro from '../pages/Cadastro';
import Perfil from '../pages/Perfil';
import ProdutosServicos from '../pages/ProdutosServicos';
import NaoEncontrado from '../pages/NaoEncontrado';
import Loading from '../components/Loading'
import Contact from '../pages/FalaComigo';


// Logged-in-only and Logged-off-only routes

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
      )
    }
  />
);
const OnlyPublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      !isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);




// Getting URL query params for specific routes

const UserProfileId = () => {
  let { id, name } = useParams();
  return <Perfil userId={id} userName={name} />
}

const PasswordResetParams = () => {
  let { token, email } = useParams();
  return <SenhaToken userToken={token} userEmail={email} />
}




// Routes

const Routes = () => (
  <BrowserRouter>
    <Loading/>
    <Switch>
      {/* Routes available to everyone */}
      <Route exact path="/" component={Home}/>
      <Route path="/usuario/perfil/:id/:name/" component={UserProfileId}/>
      <Route path="/buscar" component={ProdutosServicos}/>
      <Route path="/contato">
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} language="pt-BR">
          <Contact/>
        </GoogleReCaptchaProvider>
      </Route>


      {/* Routes available to logged-off users only */}
      <OnlyPublicRoute path="/login" component={Login} />
      <OnlyPublicRoute path="/cadastro" component={Questionario} />
      <OnlyPublicRoute path="/redefinir-senha/solicitar" component={Senha} />
      <OnlyPublicRoute path="/redefinir-senha/redefinir/:email/:token" component={PasswordResetParams}/>


      {/* Routes available to logged-in users only */}
      <PrivateRoute path="/meu-perfil/" component={Cadastro} />



      <Route path="*">
        <NaoEncontrado />
      </Route>
    </Switch>
  </BrowserRouter>
);

export default Routes;