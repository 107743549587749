import React, { useState } from 'react';
import Nav from '../../components/Navigation';
import Aside from '../../components/Aside';
import { useForm } from 'react-hook-form';
import api from '../../services/api';
import { login } from '../../services/auth';
import { useHistory } from "react-router-dom";

const profile = [
  {
    id: '1',
    url: 'temp-perfil-2.jpg',
    name: 'Carlos Campos',
    title: 'Confeiteiro',
  },
];

const Questionario = () => {

  const width = window.screen.availWidth;
  const history = useHistory();

  /**
   * Tratamento envio dados formulário
   */
  const { handleSubmit, register, errors, getValues, watch } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const [emailTaken, setEmailTaken] = useState();
  const [formSubmissionError, setFormSubmissionError] = useState();
  const [brokenEmail, setBrokenEmail] = useState();
  const currentEmail = watch('email');

  const onSubmit = async({ email, password, name }) => {
      try {
        await api.post(
          '/users',
          {
            email: email,
            name: name,
            password: password
          });

          const loginResponse = await api.post(
            '/auth/authenticate',
            {
              email: email,
              password: password
            });
          login(loginResponse.data);

          setFormSubmissionError(false);
          setEmailTaken(false);
          history.push('/meu-perfil');
      } catch(err){
        const errorList = err.response.data.errors;
        setFormSubmissionError(true);
        
        if(errorList.email) {
          setEmailTaken(true);
          setBrokenEmail(email);
        }
      }
  };

  return (
  
    <section className="section -entire flex -align-center b-light">

      <Nav theme="light-sm" option="back" />

      { profile && (width >= 992) && profile.map( (persona,index) => {

        return (

          <Aside cover={persona.url} name={persona.name} title={persona.title} key={index} />

        );
      })}

      <div className="container flex">

        <div className="row">

          <div className="col -c12 -c4-lg -offset6-lg mt-9 mt-md-4 mb-2">

            <h1 className="title -median">
              Conta pra gente um pouco sobre você? 
            </h1>

            <div className="form mt-4">

              <form onSubmit={handleSubmit(onSubmit)} noValidate>

                <div className={`form -field ${errors.name ? '-error' : ''}`}>

                  <label htmlFor="name">
                    Qual é o seu nome?
                  </label>

                  <div className="form -group">

                    <input
                      type="text"
                      id="name"
                      name="name"
                      autoComplete="false"
                      placeholder="Nome"
                      ref={register({
                        required: true,
                        minLength: 3,
                        maxLength: 80
                      })}
                    />

                    { errors.name &&
                      <p className="form -message -error">Digite um nome válido</p>
                    }

                  </div>

                </div>

                <div className={`form -field ${ errors.email || (emailTaken && (currentEmail === brokenEmail)) ? '-error' : ''}`}>

                  <label htmlFor="email">
                    Qual o seu e-mail?
                  </label>

                  <div className="form -group">

                    <input
                      type="email"
                      id="email"
                      name="email"
                      autoComplete="nope"
                      placeholder="Seu e-mail"
                      ref={register({
                        required: true,
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "* E-mail inválido. Por favor preencha novamente."
                        }
                      })}
                    />

                    { errors.email &&
                      <p className="form -message -error">Por favor, insira um endereço de e-mail válido</p>
                    }

                    { emailTaken && (currentEmail === brokenEmail) &&
                      <p className="form -message -error">Este e-mail já foi cadastrado. Tente novamente.</p>
                    }

                  </div>

                </div>

                <div className={`form -field ${errors.password ? '-error' : ''}`}>

                  <label htmlFor="password">
                    Insira uma senha 
                    <small> (6 a 16 caracteres):</small>
                  </label>

                  <div className="form -group">

                    <input
                      type="password"
                      id="password"
                      name="password"
                      autoComplete="false"
                      placeholder="Escreva aqui"
                      ref={register({
                        required: true,
                        minLength: 6,
                        maxLength: 16
                      })}
                    />

                    { errors.password &&
                      <p className="form -message -error">Por favor, insira uma senha com no mínimo 6 e no máximo 16 caracteres</p>
                    }

                  </div>

                </div>

                <div className={`form -field ${errors.paaswordRe ? '-error' : ''}`}>

                  <label htmlFor="passwordRe">
                    Repita a senha 
                    <small> (igual à digitada acima):</small>
                  </label>

                  <div className="form -group">

                    <input
                      type="password"
                      id="passwordRe"
                      name="passwordRe"
                      autoComplete="false"
                      placeholder="Escreva aqui"
                      ref={register({
                        validate: value => value === getValues('password')
                      })}
                    />

                    { errors.passwordRe &&
                      <p className="form -message -error">Sua senha precisa ser igual à inserida no campo acima</p>
                    }

                  </div>

                </div>

                <div className="form -field">

                  <div className="form -group">

                    <p className="text -tiny">
                      <small>
                        Ao clicar em <b>Continuar</b>, você concorda com nossos <a href={`${process.env.REACT_APP_PUBLIC_STORAGE_BASE_URL}Termos_e_Condicoes_dos_Servicos_do_Marketplace_Solidario.pdf`}>termos, política de privacidade e política de uso de cookies</a>. Você poderá receber notificações via e-mail e SMS da nossa equipe.
                      </small>
                    </p>

                  </div>

                </div>

                <div className="form -field center mt-2">

                  {formSubmissionError &&
                    <p className="form -message -error">Houve um erro ao iniciar seu cadastro.</p>
                  }

                  <button type="submit" className="button -link -median b-1">
                    <span className="button -text">
                      Continuar
                    </span>
                  </button>

                </div>

              </form>

            </div>
          
          </div>

        </div>

      </div>

    </section>

  );
};

export default Questionario;