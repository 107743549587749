import React from 'react';

const ProfilePicture = ({title, path}) => {

  const imagePath = path ? 
    process.env.REACT_APP_PUBLIC_STORAGE_BASE_URL + path : 
    require('../../assets/images/profilePlaceholder.svg');

  return (
      <img src={imagePath} alt={title} />
  );
}

export default ProfilePicture;