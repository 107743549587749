import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Nav from '../../../components/Navigation';
import Aside from '../../../components/Aside';
import Lottie from 'react-lottie';
import animationData from '../../../animations/success.json';
import { useForm } from 'react-hook-form';
import api from '../../../services/api';

const profile = [
  {
    id: '1',
    url: 'temp-perfil-2.jpg',
    name: 'Carlos Campos',
    title: 'Confeiteiro',
  },
];

const SenhaToken = ({ userToken, userEmail }) => {

  const width = window.screen.availWidth;

  const lottieData = {
    loop: false,
    speed: 0.00001,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const [ resetSuccess, setResetSuccess ] = useState(false);
  const [ resetFail, setResetFail ] = useState(false);

  /**
   * Tratamento envio dados formulário
   */
  const { handleSubmit, register, errors, getValues } = useForm();

  const onSubmit = async({ password, passwordRe }) => {
    try {
      await api.post(
        '/users/forgot-password/reset',
        {
          email: userEmail,
          token: userToken,
          password: password,
          password_confirmation: passwordRe
        });
        setResetSuccess(true);
    } catch(err){
      setResetFail(true);
    }
  };

  return (
  
    <section className="section -entire flex -align-center b-light">

      <Nav theme="light-sm" option="back" />

      { profile && (width >= 992) && profile.map( (persona,index) => {

        return (

          <Aside cover={persona.url} name={persona.name} title={persona.title} key={index} />

        );
      })}

      <div className="container flex">

        <div className="row">

          <div className="col -c12 -c4-lg -offset6-lg mt-9 mt-md-4 mb-2">

            <h1 className="title -median">
              Redefinição de senha
            </h1>

            <div className="form mt-4">

            {!userEmail && !userToken &&
              <label htmlFor="password">
                Você precisa usar o link que recebeu por e-mail para redefinir sua senha.
              </label>
            }

            {resetFail &&
              <div className="form -field center mt-3">
                <label htmlFor="password" className="mb-3">
                  Sua tentativa de redefinição de senha falhou. Talvez seu token tenha expirado.
                </label>
                <Link to="/redefinir-senha/solicitar" style={{textDecoration: 'none'}}>
                  <button type="button" className="button -link -median b-1">
                    <span className="button -text">
                      Solicitar novo token
                    </span>
                  </button>
                </Link>
              </div>
            }

            {resetSuccess &&
              <div className="form -field center mt-3">
                <Lottie 
                  options={lottieData}
                  height={300}
                  width={300}
                  isStopped={false}
                  isPaused={false}
                />
                <label htmlFor="password" className="mb-3">
                  Sua senha foi redefinida com sucesso!
                </label>
                <Link to="/login" style={{textDecoration: 'none'}}>
                  <button type="button" className="button -link -median b-1">
                    <span className="button -text">
                      Ir para o login
                    </span>
                  </button>
                </Link>
              </div>
            }

            {userToken && userEmail && !resetSuccess && !resetFail &&

              <form onSubmit={handleSubmit(onSubmit)} noValidate>

                <div className={`form -field ${ errors.password ? '-error' : ''}`}>

                  <label htmlFor="password">
                    Digite sua nova senha
                  </label>
                  <div className="form -group">
                    <input
                      type="password"
                      id="password"
                      name="password"
                      autoComplete="off"
                      placeholder="Nova senha"
                      ref={register({
                        required: true,
                        minLength: 6,
                        maxLength: 16
                      })}
                    />
                    { errors.password &&
                      <p className="form -message -error">Por favor, insira uma senha com no mínimo 6 e no máximo 16 caracteres</p>
                    }
                  </div>

                </div>

                <div className={`form -field ${ errors.passwordRe ? '-error' : ''}`}>

                  <label htmlFor="passwordRe">
                    Digite sua nova senha
                  </label>

                  <div className="form -group">
                    <input
                      type="password"
                      id="passwordRe"
                      name="passwordRe"
                      autoComplete="off"
                      placeholder="Nova senha"
                      ref={register({
                        validate: value => value === getValues('password')
                      })}
                    />
                    { errors.passwordRe &&
                      <p className="form -message -error">Sua senha precisa ser igual à inserida no campo acima</p>
                    }
                  </div>

                </div>

                <div className="form -field center mt-3">
                  <button type="submit" className="button -link -median b-1">
                    <span className="button -text">
                      Redefinir senha
                    </span>
                  </button>
                </div>

              </form>
                

              }

            </div>
          
          </div>

        </div>

      </div>

    </section>

  );
};

export default SenhaToken;