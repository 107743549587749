import React, { useState } from 'react';
import Nav from '../../components/Navigation';
import Aside from '../../components/Aside';
import { useForm } from 'react-hook-form';
import api from '../../services/api';

const profile = [
  {
    id: '1',
    url: 'temp-perfil-2.jpg',
    name: 'Carlos Campos',
    title: 'Confeiteiro',
  },
];

const Senha = () => {

  const width = window.screen.availWidth;

  /**
   * Tratamento envio dados formulário
   */
  const { handleSubmit, register, errors } = useForm();

  const [ requestSent, setRequestSent ] = useState(false);

  const onSubmit = async(data) => {
    try {
      await api.post('/users/forgot-password', {
        email: data.email
      });
      setRequestSent(true);
    } catch(err){
      window.alert('Erro interno do servidor: ' + err);
      setRequestSent(false);
    }
  };

  return (
  
    <section className="section -entire flex -align-center b-light">

      <Nav theme="light-sm" option="back" />

      { profile && (width >= 992) && profile.map( (persona,index) => {

        return (

          <Aside cover={persona.url} name={persona.name} title={persona.title} key={index} />

        );
      })}

      <div className="container flex">

        <div className="row">

          <div className="col -c12 -c4-lg -offset6-lg mt-9 mt-md-4 mb-2">

            <h1 className="title -median">
              {requestSent ? 'Ok!' : 'Esqueceu sua senha?'}
            </h1>

            <div className="form mt-4">

              {!requestSent &&
                <form onSubmit={handleSubmit(onSubmit)} noValidate>

                  <div className={`form -field ${ errors.email ? '-error' : ''}`}>

                    <label htmlFor="email">
                      Digite o seu e-mail
                    </label>

                    <div className="form -group">

                      <input
                        type="email"
                        id="email"
                        name="email"
                        autoComplete="off"
                        placeholder="Seu e-mail"
                        ref={register({
                          required: 'Por favor preencha o e-mail.',
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "* E-mail inválido. Por favor preencha novamente."
                          }
                        })}
                      />

                      { errors.email &&
                        <p className="form -message -error">{errors.email.message}</p>
                      }

                    </div>

                  </div>

                  <div className="form -field center mt-3">

                    <button type="submit" className="button -link -median b-1">
                      <span className="button -text">
                        Enviar e-mail
                      </span>
                    </button>

                  </div>

                </form>
              }
              {requestSent &&
                <div>
                  <h3 className="title -smaller">Foi enviada uma mensagem para o e-mail informado. Siga os passos para redefinir sua senha.</h3>
                </div> 
              }

            </div>
          
          </div>

        </div>

      </div>

    </section>

  );
};

export default Senha;