import axios, * as others from "axios";
import { getToken, refreshToken } from "./auth";

const thisModuleOthers = others;

// Regex to verify if it's and absolute URL
const isAbsoluteURLRegex = /^(?:\w+:)\/\//;

// Base URL for the API
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URI
});




// Interceptor: Request
api.interceptors.request.use(async config => {

  // Dealing with Token
  const token = getToken();
  if (token && (thisModuleOthers || true)) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  // Adding base url
  if ( !isAbsoluteURLRegex.test(config.url) ) {
    config.url = process.env.REACT_APP_API_BASE_URI + config.url;
  }

  // Starting loader
  const spinnerNode = document.querySelector('#loadingSpinner');
  if(spinnerNode.classList.contains('-off') && !config.noSpinner) {
    spinnerNode.classList.remove('-off');
  }

  return config;
});

// Interceptor: Response
api.interceptors.response.use(async config => {

  // Stopping loader
  const spinnerNode = document.querySelector('#loadingSpinner');
  if(!spinnerNode.classList.contains('-off')) {
    spinnerNode.classList.add('-off');
  }

  return config;

  // Intercepting errors for refreshing Token
}, async error => {
  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry && error.response.data.error === 'token_expired') {
    originalRequest._retry = true;
    const newAccessToken = await refreshToken();            
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + newAccessToken;
    return api(originalRequest);
  }
  const spinnerNode = document.querySelector('#loadingSpinner');
  if(!spinnerNode.classList.contains('-off')){
    spinnerNode.classList.add('-off');
  }
  return Promise.reject(error);
})


export default api;