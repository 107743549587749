import React from 'react';
import ProfileShare from '../Share';

const ProfilePublicDetail = ({ userData }) => {
  return (
    <>
      {userData &&
        <section className="register -header">
          <div className="container">
            <div className="register -header-cnt" key="cabecalho">
              <div className="register -profile" key={userData.user.id}>
                <picture className="register -image">
                  {userData.user.profilePicture &&
                    <div className="image-container" style={{backgroundImage: `url('${process.env.REACT_APP_PUBLIC_STORAGE_BASE_URL + userData.user.profilePicture}')`}} />
                  }
                  {!userData.user.profilePicture &&
                    <div className="image-container" style={{backgroundImage: `url('${require('../../../assets/images/profilePlaceholder.svg')}')`}} />
                  }
                  {userData.option &&
                    <span className={`register -flag ${userData.option}`}>
                      {userData.option}
                    </span>
                  }
                </picture>
                <div className="register -info">
                  {userData.user.name &&
                    <h4 className="register -name">
                      <span>{userData.user.name}</span>
                    </h4>
                  }
                  <div className="register -details">
                    {userData.user.profissao &&
                      <h3 className="register -title">
                        <span>{userData.user.profissao.name}</span>
                      </h3>
                    }
                    <span className="c-inverse trade-divider">
                      |
                    </span>
                    {!!userData.user.tradeDinheiro &&
                      <h3 className="register -title">
                        <span className={`vnetwork -flag payment-white`}>
                          Dinheiro
                        </span>
                        <span>Dinheiro</span>
                      </h3>
                    }
                    {!!userData.user.tradePermuta &&
                      <h3 className="register -title">
                        <span className={`vnetwork -flag favor-white`}>
                          Permuta
                        </span>
                        <span>Permuta</span>
                      </h3>
                    }
                    {userData.likes &&
                      <div className="register -fans -wrap">
                        {userData.fans && userData.fans.map( (fans,index) => {
                          return (
                            <div className="register -fans -item" key={index}>
                              <img src={require('../../../assets/images/temp/' + fans.url)} alt="curtida" />
                            </div>
                          );
                        })}
                        <span className="register -fans -likes">
                          {userData.likes} curtidas
                        </span>
                      </div>
                    }
                  </div>
                </div>
              </div>
              <div className="register -abstract">
                {userData.user.aboutSelf &&
                  <p>
                    {userData.user.aboutSelf}
                  </p>
                }
              </div>
            </div>
            <ProfileShare isOwn={false} userData={userData.user} />
          </div>
        </section>
      }
    </>
  );
};

export default ProfilePublicDetail;