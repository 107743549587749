import React, { useEffect, useState } from 'react';
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { useHistory } from "react-router-dom";
import api from '../../services/api';
import { updateUserInfo } from '../../services/user';
import InlineSVG from 'svg-inline-react';
import SuccessEdit from './SuccessEdit';

const ProfileStep4 = ({userData, formObject, stepStatus, setStepFinished, checkSign, stepFinished}) => {
  const history = useHistory();

  const [forceShowForm, setForceShowForm] = useState();
  
  const { handleSubmit: handleSubmit4, register: register4, errors: errors4, reset: resetForm, getValues, triggerValidation } = formObject;

  useEffect(() => {
    if(userData) {
      resetForm({
        productOne: userData.receive.products.productOne || '',
        productTwo: userData.receive.products.productTwo || '',
        productThree: userData.receive.products.productThree || '',
        productFour: userData.receive.products.productFour || '',
        productFive: userData.receive.products.productFive || '',
        serviceOne: userData.receive.services.serviceOne || '',
        serviceTwo: userData.receive.services.serviceTwo || '',
        serviceThree: userData.receive.services.serviceThree || '',
        serviceFour: userData.receive.services.serviceFour || '',
        serviceFive: userData.receive.services.serviceFive || '',
  
        availableToReceiveWhen: userData.availableToReceiveWhen || '',
        availableToReceiveTime: userData.availableToReceiveTime || ''
      });
    }
  }, [userData, resetForm]);
  
  const onSubmit = async(data) => {
    const {
      productOne,
      productTwo,
      productThree,
      productFour,
      productFive,
      serviceOne,
      serviceTwo,
      serviceThree,
      serviceFour,
      serviceFive,
      availableToReceiveWhen,
      availableToReceiveTime,
      } = data;

      const productArray = [productOne, productTwo, productThree, productFour, productFive];
      const serviceArray = [serviceOne, serviceTwo, serviceThree, serviceFour, serviceFive];

      try {
        const result = await api.post('/user-details', {
          step: 4,
          productRequestList: productArray,
          serviceRequestList: serviceArray,
          availableToReceiveWhen: availableToReceiveWhen,
          availableToReceiveTime: availableToReceiveTime
        });
        await updateUserInfo(result.data);
        setStepFinished(true);
        setForceShowForm(false);
        history.push('/meu-perfil');
      } catch(err){
        window.alert('Erro interno do servidor: ' + err);
        setStepFinished(false);
        setForceShowForm(false);
      }
  };

  return (
    <AccordionItem className="windbox -item">
      <AccordionItemHeading className="windbox -head">
        <AccordionItemButton className="windbox -button">
          <div className="windbox -button-target">
            {stepStatus['4'] &&
              <InlineSVG src={checkSign} className="windbox -position -finished"/>
            }
            {!stepStatus['4'] &&
              <span className="windbox -position">4</span>
            }
            <h3 className="windbox -title">Minhas necessidades</h3>
          </div>
          <span className="windbox -icon">
            <i className="icon"></i>
            <span className="sr-only">abrir</span>
          </span>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel className="windbox -panel">
        <div className="windbox -content">
          <form onSubmit={ handleSubmit4(onSubmit)} noValidate className={`${!stepFinished || forceShowForm ? '' : 'form -hide'}`}>
            <div className={`form -field ${errors4.productOne ? '-error' : ''}`}>
              <label htmlFor="produto-precisa-1">
                Quais são os cinco produtos principais de que você precisa?
              </label>
              <div className="form -group -gray">
                <input
                  type="text"
                  id="productOne"
                  name="productOne"
                  autoComplete="off"
                  placeholder="Primeiro produto"
                  onChange={() => triggerValidation('serviceOne')}
                  ref={register4({
                    maxLength: 60,
                    validate: value => !(value.length < 3 && getValues('serviceOne').length < 3)
                  })}
                />
                { errors4.productOne?.type === 'maxLength' &&
                  <p className="form -message -error">Você pode digitar no máximo 60 caracteres aqui</p>
                }
                { errors4.productOne && errors4.productOne?.type !== 'maxLength' &&
                  <p className="form -message -error">Você precisa inserir ao menos um produto ou serviço que quer oferecer</p>
                }
              </div>
              <div className="form -group -gray mt-1">
                <input
                  type="text"
                  id="productTwo"
                  name="productTwo"
                  autoComplete="off"
                  placeholder="Segundo produto"
                  ref={register4({
                    maxLength: 60
                  })}
                />
                { errors4.productTwo &&
                  <p className="form -message -error">Você pode digitar no máximo 60 caracteres aqui</p>
                }
              </div>
              <div className="form -group -gray mt-1">
                <input
                  type="text"
                  id="productThree"
                  name="productThree"
                  autoComplete="off"
                  placeholder="Terceiro produto"
                  ref={register4({
                    maxLength: 60
                  })}
                />
                { errors4.productThree &&
                  <p className="form -message -error">Você pode digitar no máximo 60 caracteres aqui</p>
                }
              </div>
              <div className="form -group -gray mt-1">
                <input
                  type="text"
                  id="productFour"
                  name="productFour"
                  autoComplete="off"
                  placeholder="Quarto produto"
                  ref={register4({
                    maxLength: 60
                  })}
                />
                { errors4.productFour &&
                  <p className="form -message -error">Você pode digitar no máximo 60 caracteres aqui</p>
                }
              </div>
              <div className="form -group -gray mt-1">
                <input
                  type="text"
                  id="productFive"
                  name="productFive"
                  autoComplete="off"
                  placeholder="Quinto produto"
                  ref={register4({
                    maxLength: 60
                  })}
                />
                { errors4.productFive &&
                  <p className="form -message -error">Você pode digitar no máximo 60 caracteres aqui</p>
                }
              </div>
            </div>
            <div className={`form -field ${errors4.serviceOne ? '-error' : ''}`}>
              <label htmlFor="servico-precisa-1">
                Quais são os cinco serviços principais de que você precisa?
              </label>
              <div className="form -group -gray">
                <input
                  type="text"
                  id="serviceOne"
                  name="serviceOne"
                  autoComplete="off"
                  placeholder="Primeiro serviço"
                  onChange={() => triggerValidation('productOne')}
                  ref={register4({
                    maxLength: 60,
                    validate: value => !(value.length < 3 && getValues('productOne').length < 3)
                  })}
                />
                { errors4.serviceOne?.type === 'maxLength' &&
                  <p className="form -message -error">Você pode digitar no máximo 60 caracteres aqui</p>
                }
                { errors4.serviceOne && errors4.serviceOne?.type !== 'maxLength' &&
                  <p className="form -message -error">Você precisa inserir ao menos um produto ou serviço que quer oferecer</p>
                }
              </div>
              <div className="form -group -gray mt-1">
                <input
                  type="text"
                  id="serviceTwo"
                  name="serviceTwo"
                  autoComplete="off"
                  placeholder="Segundo serviço"
                  ref={register4({
                    maxLength: 60
                  })}
                />
                { errors4.serviceTwo &&
                  <p className="form -message -error">Você pode digitar no máximo 60 caracteres aqui</p>
                }
              </div>
              <div className="form -group -gray mt-1">
                <input
                  type="text"
                  id="serviceThree"
                  name="serviceThree"
                  autoComplete="off"
                  placeholder="Terceiro serviço"
                  ref={register4({
                    maxLength: 60
                  })}
                />
                { errors4.serviceThree &&
                  <p className="form -message -error">Você pode digitar no máximo 60 caracteres aqui</p>
                }
              </div>
              <div className="form -group -gray mt-1">
                <input
                  type="text"
                  id="serviceFour"
                  name="serviceFour"
                  autoComplete="off"
                  placeholder="Quarto serviço"
                  ref={register4({
                    maxLength: 60
                  })}
                />
                { errors4.serviceFour &&
                  <p className="form -message -error">Você pode digitar no máximo 60 caracteres aqui</p>
                }
              </div>
              <div className="form -group -gray mt-1">
                <input
                  type="text"
                  id="serviceFive"
                  name="serviceFive"
                  autoComplete="off"
                  placeholder="Quinto serviço"
                  ref={register4({
                    maxLength: 60
                  })}
                />
                { errors4.serviceFive &&
                  <p className="form -message -error">Você pode digitar no máximo 60 caracteres aqui</p>
                }
              </div>
            </div>
            <div className={`form -field ${ errors4.availableToReceiveWhen ? '-error' : ''}`}>
              <label htmlFor="availableToReceiveWhen">
                Quando você está disponível para recebê-los (hora/dia)?
              </label>
              <div className="form -group -gray">
                <input
                  type="text"
                  id="availableToReceiveWhen"
                  name="availableToReceiveWhen"
                  autoComplete="off"
                  placeholder="ex.: de 8h às 18h de segunda à sexta"
                  ref={register4({
                    required: true,
                    maxLength: 180
                  })}
                />
                { errors4.availableToReceiveWhen &&
                  <p className="form -message -error">Por favor preencha a sua disponibilidade.</p>
                }
              </div>
            </div>
            <div className={`form -field ${ errors4.availableToReceiveTime ? '-error' : ''}`}>
              <label htmlFor="availableToReceiveTime">
                Com que frequência (quantidade máxima de tempo)?
              </label>
              <div className="form -group -gray">
                <input
                  type="text"
                  id="availableToReceiveTime"
                  name="availableToReceiveTime"
                  autoComplete="off"
                  placeholder="ex.: 6h por dia"
                  ref={register4({
                    required: true,
                    maxLength: 180
                  })}
                />
                { errors4.availableToReceiveTime &&
                  <p className="form -message -error">Por favor preencha a sua frequência.</p>
                }
              </div>
            </div>
            <div className="form -field center mt-4">
              <button type="submit" className="button -register -median">
                <span className="button -text">
                  Salvar e continuar
                </span>
              </button>
            </div>
          </form>
          {stepFinished && !forceShowForm &&
            <SuccessEdit setForceShow={setForceShowForm} />
          }
        </div>
      </AccordionItemPanel>
    </AccordionItem>
  );
}

export default ProfileStep4;