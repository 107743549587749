import React from 'react';
import ReactDom from 'react-dom';
import * as Sentry from '@sentry/browser';

import App from './App';

//import {createStore} from 'redux';

Sentry.init({
  dsn: "https://712d5b5902e34bfdb5dcf367e5ab40fc@o262655.ingest.sentry.io/5218393",
  environment:  process.env.REACT_APP_ENV
});

ReactDom.render(<App />, document.getElementById('root'));
