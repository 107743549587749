import React from 'react';
import InlineSVG from 'svg-inline-react';
import Lottie from 'react-lottie';
import animationData from '../../../animations/loadingSearch.json';

const svgFilter = '<svg viewBox="0 0 20 19.353" xmlns="http://www.w3.org/2000/svg"><path d="m27.7 40.632h-6.825a2.724 2.724 0 0 0 -5.317 0h-6.658a.6.6 0 1 0 0 1.2h6.659a2.724 2.724 0 0 0 5.317 0h6.824a.593.593 0 0 0 .6-.6.625.625 0 0 0 -.6-.6zm-9.485 2.132a1.533 1.533 0 1 1 1.533-1.533 1.544 1.544 0 0 1 -1.532 1.532z" transform="translate(-8.3 -31.578)"/><path d="m11.131 15.061a2.723 2.723 0 0 0 2.659-2.132h13.963a.6.6 0 1 0 0-1.2h-13.964a2.728 2.728 0 1 0 -2.659 3.329zm0-4.263a1.533 1.533 0 1 1 -1.531 1.533 1.544 1.544 0 0 1 1.531-1.531z" transform="translate(-8.376 -9.6)"/><path d="m25.546 67.6a2.747 2.747 0 0 0 -2.659 2.108h-13.987a.6.6 0 1 0 0 1.2h13.987a2.726 2.726 0 1 0 2.659-3.308zm0 4.24a1.533 1.533 0 1 1 1.533-1.533 1.544 1.544 0 0 1 -1.533 1.533z" transform="translate(-8.3 -53.708)" fill="#525354"/></svg>';
const svgSelect = '<svg viewBox="0 0 19.003 10" xmlns="http://www.w3.org/2000/svg"><path d="m10 9.5-.671-.774-7.546-8.726-1.783 1.548 6.875 7.952-6.875 7.955 1.783 1.545 7.546-8.728z" transform="matrix(0 1 -1 0 19.003 0)" fill="#525354"/></svg>';

const Filter = ({ searchValue, setSearchValue }) => {
  const width = window.screen.availWidth;

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };



  return (
    <section className="filter -wrap">
      <div className="container">
        <h2 className="title -smaller -strong">Encontre alguém com quem você possa contar</h2>
        <div className="filter -config">
          <div className="filter -col -col-2">
            <div className="filter -search">
              <form>
                <h3 className="title -chapter mt-1">O que você quer encontrar</h3>
                <div className="filter -search-group">
                  <input 
                    type="search"
                    name="search"
                    id="search"
                    placeholder="Produtos, serviços ou outros"
                    defaultValue={searchValue}
                    autoComplete="off"
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                  <input type="submit" name="submit" id="submit" value="" />
                </div>
                <div className="spinner-container -off" id="searchBoxLoader">
                  <Lottie 
                    options={lottieOptions}
                    height={60}
                    width={60}
                    isStopped={false}
                    isPaused={false}
                  />
                </div>
              </form>
            </div>
          </div>
          { false &&
            <div className="filter -col -col-1">
              <div className="filter -actions">
                <div className="filter -action">
                  <button className="filter -action-button -icon" type="button">
                    <span>Próximos de mim</span>
                    <InlineSVG src={svgSelect} />
                  </button>
                </div>
                <div className="filter -action">
                  <button className="filter -action-button -icon" type="button">
                    <span>Filtrar</span>
                    <InlineSVG src={svgFilter} />
                  </button>
                </div>
              </div>
            </div>
          }
          { false && width >= 992 && (
            <div className="filter -col -col-2">
              <h3 className="title -chapter mt-1">Popular no ContaComigo</h3>
              <div className="filter -actions">
                <div className="filter -action">
                  <button className="filter -action-button" type="button">
                    <span>Marceneiro</span>
                  </button>
                </div>
                <div className="filter -action">
                  <button className="filter -action-button" type="button">
                    <span>Costureira</span>
                  </button>
                </div>
                <div className="filter -action">
                  <button className="filter -action-button" type="button">
                    <span>Encanador</span>
                  </button>
                </div>
                <div className="filter -action">
                  <button className="filter -action-button" type="button">
                    <span>Bombeiro hidráulico</span>
                  </button>
                </div>
                <div className="filter -action">
                  <button className="filter -action-button" type="button">
                    <span>Faxineira</span>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Filter;