import React, { useEffect, useState } from 'react';
import MaskedInput from "react-input-mask";
import { useForm, Controller } from 'react-hook-form';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Lottie from 'lottie-react-web';
import api from '../../services/api';
import useIntersectObserver from '../../constants/useIntersectObserver';
import VisualNetwork from "../../components/VisualNetwork";
import Menu from '../../components/Menu';
import animationSuccess from '../../animations/success.json';


const Contact = () => {

  const { executeRecaptcha } = useGoogleReCaptcha();
  const recaptchaToken = executeRecaptcha('contact');

  const [success, setSuccess] = useState();

  const { handleSubmit, register, errors, control } = useForm();

  const onSubmit = async(data) => {
    const { name, email, whatsapp, subject, message } = data;

      try {
        await api.post('/contact', {
          name: name,
          email: email,
          whatsapp: whatsapp,
          subject: subject,
          message: message,
          token: await recaptchaToken
        });
        setSuccess(true);
      } catch(err){
        window.alert('Erro interno do servidor: ' + err);
        setSuccess(false);
      }
  };

  /**
   * Animações dos elementos
   */
  const [observer, setElements, entries] = useIntersectObserver({
    threshold: 0.1,
    root: null
  });

  useEffect(() => {
    let elements = Array.from(document.getElementsByClassName('js-anime'));
    setElements(elements);
  }, [setElements]);

  useEffect(() => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        setTimeout(function() {
          entry.target.style.opacity = '1';
          entry.target.classList.remove('-motion');
          observer.unobserve(entry.target);
        }, 200);
      }
    });
  }, [entries, observer]);

  return (
    <>
    <Menu theme="quaternary"/>
    <section className="section -cover b-4" name="facaparte">
      <div className="container section -holder">
        <div className="grid -wrap -row-6-1-4 -center">
          <article className="hero -image grid -col-a">
            <h2 className="title -median c-inverse hidden-lg js-anime -anime -anime-1 -motion" style={{ opacity:'0'}}>
              Faça parte dessa rede de troca e solidariedade.
            </h2>
            <div className="js-anime -anime -anime-2 -motion" style={{ opacity:'0'}}>
              <VisualNetwork />
            </div>
          </article>
          <article className="hero -content grid -col-b">
            <div className="form mt-4 js-anime -anime -anime-5 -motion" style={{ opacity:'0'}}>

              <form onSubmit={handleSubmit(onSubmit)} noValidate className={ success ? 'hide' : 'show'}>

                <div className={`form -field ${errors.name ? '-error' : ''}`}>

                  <label htmlFor="name" className="-contact">
                    Nome
                  </label>

                  <div className="form -group">

                    <input
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Seu nome"
                      autoComplete="off"
                      maxLength="80"
                      ref={register({
                        required: true,
                        maxLength: 80,
                        minLength: 3
                      })}
                    />

                    { errors.name &&
                      <p className="form -message -contact-error">Por favor, insira um nome válido</p>
                    }

                  </div>

                </div>

                <div className={`form -field ${ errors.email ? '-error' : ''}`}>

                  <label htmlFor="email" className="-contact">
                    E-mail
                  </label>

                  <div className="form -group">

                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Seu e-mail"
                      autoComplete="off"
                      ref={register({
                        required: true,
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "* E-mail inválido. Por favor preencha novamente."
                        }
                      })}
                    />

                    { errors.email &&
                      <p className="form -message -contact-error">Por favor, insira um e-mail válido</p>
                    }

                  </div>

                </div>

                <div className={`form -field ${errors.whatsapp ? '-error' : ''}`}>

                  <label htmlFor="whatsapp" className="-contact">
                    Telefone
                  </label>

                  <div className="form -group">

                    <Controller
                      as={MaskedInput}
                      control={control}
                      name="whatsapp"
                      mask="(99) 99999-9999"
                      placeholder="(DDD) 00000-0000"
                      defaultValue=""
                      rules={{
                        required: true,
                        validate: value => (value.replace(/\D/g,'').length === 10 || value.replace(/\D/g,'').length === 11)
                      }}
                    />

                    { errors.whatsapp &&
                      <p className="form -message -contact-error">Por favor, insira seu telefone</p>
                    }

                  </div>

                </div>

                <div className="form -field">

                  <label htmlFor="subject" className="-contact">
                    Assunto
                  </label>

                  <div className="form -group">

                    <input
                      type="text"
                      id="subject"
                      name="subject"
                      placeholder="Assunto da mensagem"
                      autoComplete="off"
                      ref={register({
                        required: false,
                        maxLength: 120
                      })}
                    />

                  </div>

                </div>

                <div className={`form -field ${errors.message ? '-error' : ''}`}>

                  <label htmlFor="message" className="-contact">
                    Mensagem
                  </label>

                  <div className="form -group">

                    <textarea
                      id="message"
                      name="message"
                      autoComplete="off"
                      placeholder="Escreva aqui sua mensagem"
                      rows="2"
                      style={{resize: 'none'}}
                      ref={register({
                        required: true,
                        minLength: 6
                      })}
                    />

                    { errors.message &&
                      <p className="form -message -contact-error -area">Por favor, escreva a sua mensagem</p>
                    }

                  </div>

                </div>

                <div className="form -field mt-5">

                  <button type="submit" className="button -link -larger b-1">
                    <span className="button -text">
                      Entrar em contato
                    </span>
                  </button>

                </div>

              </form>

              { success &&
                <div className="success">
                  <Lottie
                    options={{
                      animationData: animationSuccess,
                      loop: true,
                      autoplay: true,
                    }}
                    speed={.9}
                    width="60%"
                  />
                  <span className="text center -larger c-inverse mt-0">Mensagem enviada com sucesso.</span>
                </div>
              }
            </div>
          </article>
        </div>
      </div>
    </section>
    </>
  );
}

export default Contact;
