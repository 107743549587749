import React, { useState } from "react";
import { getUserObject } from '../../services/auth'

import Menu from '../../components/Menu';
import ProfileDetail from '../../components/Profile/Detail';
import Formulario from '../../pages/Cadastro/Formulario';


const Cadastro = () => {

  const [userData, setUserData] = useState();
  const [registrationStatus, setRegistrationStatus] = useState({total: 4, ok: 0});
  const [stepStatus, setStepStatus] = useState({
    '1': false,
    '2': false,
    '3': false,
    '4': false,
    '5': false
  });

  const setProfileInfo = async () => {
    if(!userData){
      const fecthedData = await getUserObject();
      setUserData(fecthedData);
    }
  };

  setProfileInfo();
  return (
    <>
    {userData &&
      <>
        <Menu theme="quaternary"/>
        <ProfileDetail userData={userData} registrationStatus={registrationStatus} />
        <section className="register -form b-light">
          <div className="container">
            <Formulario stepStatus={stepStatus} setStepStatus={setStepStatus} setRegistrationStatus={setRegistrationStatus} />
          </div>
        </section>
      </>
    }
    </>
  );
};

export default Cadastro;