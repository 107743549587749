import React from "react";

import Menu from '../../components/Menu';
import Hero from '../../components/Hero';
import SearchBox from '../../components/SearchBox';
import FacaParte from "../../components/FacaParte";
import About from "../../components/About";
import AboutBanner from "../../components/About/Banner";
import Share from "../../components/Share";


const Home = () => {
  return (
    <>
      <Menu theme="tertiary"/>
      <Hero />
      <SearchBox />
      <FacaParte />
      <AboutBanner />
      <About />
      <Share />
    </>
  );
};

export default Home;