import React, { useEffect } from 'react';
import InlineSVG from 'svg-inline-react';
import ButtonScroll from '../../ButtonScroll';
import useIntersectObserver from '../../../constants/useIntersectObserver';

import { getUserObject } from '../../../services/auth';

const svgSymbol = '<svg enable-background="new 0 0 48.08 48.08" viewBox="0 0 48.08 48.08" xmlns="http://www.w3.org/2000/svg"><g fill="#fff" transform="translate(-2.339 -1.721)"><path d="m29.86 7.93-6.78-5.8c-.71-.6-1.77-.52-2.38.18-.61.71-.53 1.77.18 2.38l2.97 2.55c-10.12.79-17.7 9.63-16.92 19.75.43 5.6 3.4 10.7 8.07 13.84.28.2.61.3.94.3.56-.01 1.08-.29 1.4-.75.52-.78.32-1.83-.45-2.35-6.87-4.62-8.7-13.93-4.08-20.81 2.68-3.98 7.09-6.44 11.89-6.62l-3.81 3.27c-.71.61-.79 1.67-.19 2.38.61.71 1.67.79 2.38.19l6.77-5.8c.39-.34.61-.84.59-1.36.02-.52-.2-1.01-.58-1.35z"/><path d="m22.9 43.58 6.78 5.81c.71.61 1.79.52 2.39-.19.26-.31.4-.69.41-1.09 0-.49-.22-.96-.59-1.28l-2.99-2.56c10.12-.77 17.7-9.61 16.93-19.73-.43-5.61-3.4-10.71-8.07-13.85-.28-.2-.61-.3-.95-.3-.94.01-1.69.79-1.67 1.73.01.55.28 1.06.74 1.37 6.87 4.62 8.7 13.94 4.08 20.81-2.68 3.98-7.1 6.44-11.89 6.62l3.82-3.27c.71-.6.8-1.66.2-2.37 0 0-.01-.01-.01-.01-.6-.71-1.66-.8-2.37-.2 0 0-.01.01-.01.01l-6.78 5.8c-.39.34-.6.83-.58 1.35-.04.51.17 1.01.56 1.35z"/></g></svg>';

const Banner = () => {

  const authUser = getUserObject();

  /**
   * Animações dos elementos
   */
  const [observer, setElements, entries] = useIntersectObserver({
    threshold: 0.1,
    root: null
  });

  useEffect(() => {

    let elements = Array.from(document.getElementsByClassName('js-anime'));

    setElements(elements);
    
  }, [setElements]);

  useEffect(() => {

    entries.forEach(entry => {

      if (entry.isIntersecting) {

        setTimeout(function() {
          entry.target.style.opacity = '1';
          entry.target.classList.remove('-motion');

          observer.unobserve(entry.target);
        }, 200);
      }
    });
  }, [entries, observer]);

  return (
    <>

    <section className="section -cover b-inverse">

      <div className="container section -holder">

        <div className="grid -wrap -row-1-5-1-4-1">

          <article className="grid -col-b">

            <div className="banner -exchange -content flex -justify-space-between js-anime -anime -anime-1 -motion" style={{ opacity:'0'}}>

              <div className="banner -exchange -item">
                
                <img src={require('../../../assets/images/mock1.svg')} alt={'Interface ContaComigo'} />

              </div>

              <div className="banner -exchange -symbol">
                <InlineSVG src={svgSymbol} />
              </div>

              <div className="banner -exchange -item">

                <img src={require('../../../assets/images/mock2.svg')} alt={'Interface ContaComigo'} />

              </div>

            </div>

          </article>

          <article className="grid -col-a mt-6 mt-md-0">

            <h2 className="title -median -condensed js-anime -anime -anime-2 -motion" style={{ opacity:'0'}}>
              <strong>ContaComigo</strong><br />
              <span className="title -regular">é uma plataforma para as pessoas fazerem o intercâmbio de seus serviços e produtos.</span>
            </h2>

            {!authUser &&
              <ButtonScroll title="Quero fazer parte" className="js-anime -anime -anime-3 -motion" bgColor="b-dark" textColor="c-inverse" />
            }

          </article>

        </div>

      </div>

    </section>

    </>
  );
}

export default Banner;