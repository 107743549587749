import React from "react";
import VisualNetworkDetail from "../../components/VisualNetwork/Detail";

const network = [
  {
    id: '1',
    url: 'temp-persona-1.jpg',
    title: 'Bombeiro hidráulico',
    option: 'payment',
  },
  {
    id: '2',
    url: 'temp-persona-2.jpg',
    title: 'Profa. Inglês',
    option: 'payment',
  },
  {
    id: '3',
    url: 'temp-persona-3.jpg',
    title: 'Marceneiro',
    option: 'favor',
  },
  {
    id: '4',
    url: 'temp-persona-4.jpg',
    title: 'Designer Interiores',
    option: 'payment',
  },
  {
    id: '5',
    url: 'temp-persona-5.jpg',
    title: 'Confeiteiro',
    option: 'favor',
  },
  {
    id: '6',
    url: 'temp-persona-6.jpg',
    title: 'Costureira',
    option: 'payment',
  },
  {
    id: '7',
    url: 'temp-persona-7.jpg',
    title: 'Pintor',
    option: 'favor',
  },
  {
    id: '8',
    url: 'temp-persona-8.jpg',
    title: 'Consultora Beleza',
    option: 'payment',
  },
  {
    id: '9',
    url: 'temp-persona-9.jpg',
    title: 'Eletricista',
    option: 'favor',
  },
  {
    id: '10',
    url: 'temp-persona-10.jpg',
    title: 'Cabeleira',
    option: 'favor',
  },
  {
    id: '11',
    url: 'temp-persona-11.jpg',
    title: 'Yoga',
    option: 'payment',
  },
  {
    id: '12',
    url: 'temp-persona-12.jpg',
    title: 'Motorista Van',
    option: 'payment',
  },
];

const VisualNetwork = () => {

  const width = window.screen.availWidth;

  let vnetwork = width >= 992 ? network : network.slice(0, 6);

  return (
    <>

    <section className="vnetwork -wrap mt-4 mt-md-0">

      <div className="vnetwork -orbit"></div>

      <article className="vnetwork -content">

        { vnetwork && vnetwork.map( (image,index) => {

          return (

            <VisualNetworkDetail 
              key={index} 
              url={image.url}
              title={image.title}
              option={image.option}
              visual="light"
            />

          );
        })}

      </article>

    </section>

    </>
  );
}

export default VisualNetwork;