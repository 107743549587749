import React, { useEffect, useState } from 'react';
import Menu from '../../components/Menu';
import ProfilePublicDetail from '../../components/Profile/PublicDetail';
import api from '../../services/api';
import { useHistory } from "react-router-dom";


/*
const profile = [
  {
    id: 'profile',
    url: 'temp-persona-13.jpg',
    name: 'Priscila Senegale Coutto',
    title: 'Redatora',
    option: 'favor',
    abstract: 'Sou redatora freelancer há quatro anos e tenho experiência com produção de conteúdo para web e SEO. Sou redatora em período integral e tenho disponibilidade para demandas urgentes e/ou recorrentes.',
    likes: 6,
    video: 'hce09j_GJRs',
    fans: [
      {
        id: '1',
        url: 'temp-persona-4.jpg',
      },
      {
        id: '2',
        url: 'temp-persona-8.jpg',
      },
      {
        id: '3',
        url: 'temp-persona-12.jpg',
      },
    ],
    offer: [
      {
        id: '1',
        url: 'Redação',
      },
      {
        id: '2',
        url: 'Revisão',
      },
      {
        id: '3',
        url: 'Correção ortográfica',
      },
      {
        id: '4',
        url: 'Pesquisa e criação',
      },
    ],
  },
];
*/

const Cadastro = ({ userId }) => {
  const history = useHistory();

  const [ userProfile, setUserprofile ] = useState();


  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const userResponse = await api.get(`/users?id=${userId}`);
        setUserprofile(userResponse.data);
      } catch(err){
        history.push('/404');
      }
    }
    if(!userProfile){
      fetchProfile();
    }
  }, [ userProfile, history, userId ]);


  const width = window.screen.availWidth;

  return (
    <>
      {userProfile &&
        <>
          <Menu theme="quaternary"/>
          <ProfilePublicDetail userData={userProfile} />
          <section className="detail -wrap b-light">
            <div className="container">
              <div className="detail -list -order-2">
                <article className="detail -box" key="productsOfferedBox">
                  <h2 className="c-4">Produtos/Serviços que ofereço</h2>
                  <div className="detail -item">
                    <div className="detail -content">
                      {userProfile.products.offer && userProfile.products.offer.map( (product,index) => {
                        return product.name ? (
                          <span className="b-4" key={`productOffer${index}`}>
                            {product.name}
                          </span>
                        ) : '';
                        })
                      }
                      {userProfile.services.offer && userProfile.services.offer.map( (service,index) => {
                        return service.name ? (
                          <span className="b-4" key={`serviceOffer${index}`}>
                            {service.name}
                          </span>
                        ) : '';
                        })
                      }
                    </div>
                  </div>
                </article>
                <article className="detail -box">
                  <h2 className="c-3">Produtos/Serviços que preciso</h2>
                  <div className="detail -item">
                    <div className="detail -content">
                      {userProfile.products.receive && userProfile.products.receive.map( (product,index) => {
                        return product.name ? (
                          <span className="b-3" key={`productReceive${index}`}>
                            {product.name}
                          </span>
                        ) : '';
                        })
                      }
                      {userProfile.services.receive && userProfile.services.receive.map( (service,index) => {
                        return service.name ? (
                          <span className="b-3" key={`serviceReceive${index}`}>
                            {service.name}
                          </span>
                        ) : '';
                        })
                      }
                    </div>
                  </div>
                </article>
                { userProfile.user.youtubeVideo && (width < 992) && 
                  <article className="detail -box" key="youtubeVideo">
                    <h2 className="c-dark">Meu vídeo</h2>
                    <div className="detail -item -item-400">
                      <div className="detail -video">
                        <iframe 
                          width="560" 
                          height="315" 
                          title="Vídeo do perfil"
                          src={`https://www.youtube.com/embed/${userProfile.user.youtubeVideo}?showinfo=0`} 
                          frameBorder="0" 
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen>
                        </iframe>
                      </div>
                    </div>
                  </article>
                }
                <article className="detail -box">
                  <h2 className="c-dark">Principais habilidades</h2>
                  <div className="detail -item">
                    <div className="detail -content">
                      <span>{userProfile.user.areaAtuacao.name}</span>
                    </div>
                  </div>
                </article>
                <article className="detail -box">
                  <h2 className="c-dark">Profissão</h2>
                  <div className="detail -item">
                    <div className="detail -content">
                      <span>{userProfile.user.profissao.name}</span>
                    </div>
                  </div>
                </article>
                {!!userProfile.user.education &&
                  <article className="detail -box">
                    <h2 className="c-dark">Cursos que tenho</h2>
                    <div className="detail -item">
                      <div className="detail -content">
                        {userProfile.user.education && 
                          userProfile.user.education.split(',').map((item, index) => {
                            return (
                              <span key={`userEducation${index}`}>{item}</span>
                            )
                          })
                        }
                      </div>
                    </div>
                </article>
                }
              </div>
              <div className="detail -list -order-1">
                { userProfile.user.youtubeVideo &&
                  <article className="detail -box" key="userYoutubeVideoTwo">
                    <h2 className="c-dark">Meu vídeo</h2>
                    <div className="detail -item -item-400">
                      <div className="detail -video">
                        <iframe 
                          width="560" 
                          height="315" 
                          title="Vídeo do perfil"
                          src={`https://www.youtube.com/embed/${userProfile.user.youtubeVideo}?showinfo=0`} 
                          frameBorder="0" 
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen>
                        </iframe>
                      </div>
                    </div>
                  </article>
                }
                <article className="detail -box">
                  <h2>Meu e-mail</h2>
                  <div className="detail -item">
                    <span>{userProfile.user.email}</span>
                  </div>
                </article>
                {userProfile.user.whatsapp &&
                  <article className="detail -box">
                    <h2>Meu whatsapp/telefone</h2>
                    <div className="detail -item">
                      <span>{userProfile.user.whatsapp}</span>
                    </div>
                  </article>
                }
                <article className="detail -box">
                  <h2>Idade</h2>
                  <div className="detail -item">
                    <span>{userProfile.user.age} anos</span>
                  </div>
                </article>
                <article className="detail -box">
                  <h2>Em que cidade estou</h2>
                  <div className="detail -item">
                    <span>{userProfile.user.city}</span>
                  </div>
                </article>
                <article className="detail -box">
                  <div className="detail -item -item-400">
                    <div className="detail -content">
                      <p>Pra saber meu endereço completo, entre em contato comigo por {userProfile.user.whatsapp ? 'telefone ou email.' : 'email.'}</p>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </section>
        </>
      }
    </>
  );
};

export default Cadastro;